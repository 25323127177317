import {Geolocation, Geoposition} from '@ionic-native/geolocation';
import React, {useState} from "react";
import { IonFooter, IonLoading, IonButton, IonSplitPane, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonToast } from '@ionic/react';
import { Preferences } from '@capacitor/preferences';
import './MapQuestButton.css';
import Geo from '../pages/Capgeo';

interface LocationError {
    showError: boolean;
    message?: string;
}

const MapQuestButton: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<LocationError>({showError: false});
    const [position, setPosition] = useState<Geoposition>();
    
    const getMapquestLocation = async () => {
        setLoading(true);
        try {
            const position = await Geolocation.getCurrentPosition();
                setPosition(position);
                setLoading(false);
                setError({ showError: false });
                const setName = async () => {
                    await Preferences.set({
                      key: 'lat',
                      value: 'position.coords.latitude',
                    });
                };
                const setName2 = async () => {
                    await Preferences.set({
                        key: 'long',
                        value: 'position.coords.longitude',
                    });
                };
               
               let stringLat  = "";
               stringLat = String(position.coords.latitude);
               let stringLong = "";
               stringLong = String(position.coords.longitude);
               let StringLocation = "";
               StringLocation = "https://mapquest.com/search/" + stringLat + "," + stringLong;
               localStorage.setItem("Mapquest", StringLocation);
               alert(StringLocation);
                //document.getElementById("mapq").innerHTML = "https://mapquest.com/search/Boise";
                //document.getElementById('mapq').innerHTML=StringLocation;   
            }
        catch (error) {
            const message = "Cannot get user location. Check permissions.";
            setError({ showError: true, message});
            setLoading(false);
        }
    }
        
      
    return(
    <>
    <IonLoading 
    isOpen={loading}
    message={"Getting location...."}
    onDidDismiss={() => setLoading(false)}
    />
    
    <IonToast
                    isOpen={error.showError}
                    onDidDismiss={() => setError({ message: "", showError: false })}
                    message={error.message}
                    duration={3000}
                />
    <IonButton className="GLILOCBUTTON4" slot="end" color="success" onClick={getMapquestLocation}> {position ? `${position.coords.latitude} ${position.coords.longitude}` : "MapQuest"}</IonButton>
    
    </>
    );
    
    }
    
    export default MapQuestButton;