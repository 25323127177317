import {Geolocation, Geoposition} from '@ionic-native/geolocation';
import React, {useState} from "react";
import { IonIcon, IonFooter, IonLoading, IonButton, IonSplitPane, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonToast } from '@ionic/react';
import { Preferences } from '@capacitor/preferences';
import './GeoButton.css';
import { logoIonic, mapSharp, locationSharp } from 'ionicons/icons';

interface LocationError {
    showError: boolean;
    message?: string;
}

const GeoLocationButton: React.FC = () => {
const [loading, setLoading] = useState<boolean>(false);
const [error, setError] = useState<LocationError>({showError: false});
const [position, setPosition] = useState<Geoposition>();

const getLocation = async () => {
    setLoading(true);
    try {
        const position = await Geolocation.getCurrentPosition();
            setPosition(position);
            setLoading(false);
            setError({ showError: false });
            const setName = async () => {
                await Preferences.set({
                  key: 'lat',
                  value: 'position.coords.latitude',
                });
            };
            const setName2 = async () => {
                await Preferences.set({
                    key: 'long',
                    value: 'position.coords.longitude',
                });
            };
           
           const stringLat  = String(position.coords.latitude);
           const stringLong = String(position.coords.longitude);
           const StringLocation = stringLat + "," + stringLong;
           localStorage.setItem("lat", stringLat);
           localStorage.setItem("long", stringLong);
           localStorage.setItem("userlocation", StringLocation);
           localStorage.setItem("jstatus", "loggedin")
           alert(position.coords.latitude + "," + position.coords.longitude);    
        }
    catch (error) {
        const message = "Cannot get user location. Check permissions.";
        setError({ showError: true, message});
        setLoading(false);
    }
}
    
  
return(
<>
<IonLoading 
isOpen={loading}
message={"Getting location...."}
onDidDismiss={() => setLoading(false)}
/>

<IonToast
                isOpen={error.showError}
                onDidDismiss={() => setError({ message: "", showError: false })}
                message={error.message}
                duration={3000}
            />
<IonButton className="GLILOCBUTTON2" color="success" slot="end" onClick={getLocation}><IonIcon icon={locationSharp}> </IonIcon>LocationToggle</IonButton>

</>
);

}

export default GeoLocationButton;